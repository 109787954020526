import React, {Component} from 'react';
import {graphql} from 'gatsby';
import parse from 'html-react-parser';
import BaseLayout from '../components/Layout/BaseLayout';

class DefaultPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        };

    }

    componentDidMount() {
        this.setState({
            show: true
        });
    }


    render() {

        const { data, location } = this.props;
        return (
            <BaseLayout show={this.state.show} data={data.gravql} location={location} crumbLabel={data.gravql.page.title}>
                <div className="legal">
                <h1>{data.gravql.page.title}</h1>
                <ul>
                    {data.gravql.page.children.map((child, x) => {
                        return <li><a key={child.title} href={`#${x}`} style={{display:'block'}}>{child.title}</a></li>
                    })}
                </ul>
                {data.gravql.page.children.map((child, x) => {
                    return(
                        <div key={child.title} id={x} className="offset">
                            <h2>{child.title}</h2>
                            {
                                parse(child.content)
                            }
                            {(child.title === "4.\tCookies die wij gebruiken") &&
                                <>
                                    <div id="ot-sdk-cookie-policy"></div>
                                    <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
                                </>
                            }
                        </div>)
                })}
                </div>

            </BaseLayout>
        )
    }
}

export default DefaultPage;

export const query = graphql`
 query ($slug:String!) {
    gravql {
        pages {
            title
            route
            visible
            header {
                title
                body_classes
            }
            children {
                title
                route
                content
                header {
                    title
                    body_classes
                }
                 children {
                    title
                    route
                    content
                    header {
                        title
                        body_classes
                    }
                }
            }
        }
        page(route: $slug) {
            title      
            content
            visible
            route
            breadcrumbs
            header {
                title
                body_classes
            }
            children {
                title
                route    
                content   
                 children {
                    title
                    route
                }         
            }
            imagesCropped(crop:true, width: "200", height:"200"){
                path
                filename
                url
                width
                height
            }
            metadata {
                name
                content
            }   
        }
    }
  }
`